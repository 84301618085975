import './styles.css'
import { Form, Select, Input, Button } from 'antd'
import { WechatOutlined } from '@ant-design/icons'
import { useMemo, useState } from 'react'
import useHttp from '../../../hooks/useHttp'
import {
  selectAppInfo,
  setIsAppOpen,
  selectAppId,
  selectStatusesOnly,
  setAppId,
  getApplications,
  getStatuses,
  getAppComments,
  setAppComments,
  getFileAppInfo,
} from '../../../slices/applicationSlice'
import { useSelector, useDispatch } from 'react-redux'
import { APLICATION_STATUSES, Role, roles, SOLUTIONS_METHODS } from '../../../constants'
import { confirm, success } from '../../../constants/helpers'
import Urls from '../../../constants/urls'
import { useUserInfo } from '../../../hooks/useUserInfo'
import { selectSolutionMethods } from '../../../slices/variableListSlice'
import ChangePersWindow from '../ChangePersWindow'
import CommentsWindow from '../../Comment/CommentsWindow'
const { Option } = Select

const ApplicationWork = ({ isSearch }) => {
  const dispatch = useDispatch()
  const { postRequest } = useHttp()

  const urls = Urls()
  const appId = useSelector(selectAppId)
  const application = useSelector(selectAppInfo)
  const statusList = useSelector(selectStatusesOnly)
  const solutionMethods = useSelector(selectSolutionMethods)

  const isComment = useMemo(() => application.notif !== 0, [application])
  const isNewStatus = useMemo(
    () => application.app_status === APLICATION_STATUSES.new,
    [application]
  )
  const isInWorkStatus = useMemo(
    () => application.app_status === APLICATION_STATUSES.inwork,
    [application]
  )
  const isFinishedStatus = useMemo(
    () =>
      application.app_status === APLICATION_STATUSES.finished ||
      application.app_status === APLICATION_STATUSES.prefinished,
    [application]
  )

  const { userClientId, userPersId } = useUserInfo()
  const role = Role()

  const isPersonalRole = useMemo(() => role === roles.support, [role])
  const isClientRole = useMemo(
    () => role === roles.client || role === roles.clientAdmin,
    [role]
  )

  const [status, setStatus] = useState('')
  const [openComments, setOpenComments] = useState(false)
  const [openChangePers, setOpenChangePers] = useState(false)
  const [solutionMethod, setSolutionMethod] = useState(SOLUTIONS_METHODS.script)

  //Колбэки
  const statusChangeCallback = (response) => {
    if (!isSearch) {
      const obj = {
        P_PERS_ID: userPersId,
      }
      dispatch(getStatuses(urls.GetStatList, obj))
      status === 2
        ? dispatch(getApplications(urls.GetNewAppList, obj))
        : dispatch(getApplications(urls.GetInWorkList, obj))
    }
    success(response.data[0].response, `Заявка № ${application.app_num}`)
    dispatch(setIsAppOpen(false))
    dispatch(setAppId(''))
  }

  //Слушатели событий
  const onClose = () => {
    dispatch(setIsAppOpen(false))
    dispatch(setAppId(''))
  }

  const onChangeStatus = async () => {
    const obj = {
      P_APP_ID: appId,
      P_APP_STAT: status,
      P_PERS_ID: userPersId,
      P_TYPE_CLOSING: solutionMethod,
    }
    const url = urls.AppStatChange

    if (!isNewStatus) {
      const title = 'Вы действительно хотите закрыть заявку?'
      status && confirm(title, obj, url, statusChangeCallback, postRequest)
    } else {
      status &&
        postRequest({
          url: url,
          data: obj,
          callback: statusChangeCallback,
        })
    }
  }

  const onToTest = () => {
    const obj = {
      P_APP_ID: appId,
      P_APP_STAT: 3,
      P_PERS_ID: userPersId,
    }
    postRequest({
      url: urls.AppStatChange,
      data: obj,
      callback: statusChangeCallback,
    })
  }
  const onOpenComments = () => {
    setOpenComments(true)
    const obj = isClientRole
      ? {
          p_app_id: appId,
          p_client_id: userClientId,
        }
      : {
          p_app_id: appId,
        }
    dispatch(getAppComments(urls.GetMess, obj))
    // isComment
    //   ? dispatch(getAppComments(urls.GetMess, obj)) //Проверка на присутсвтие комменатриев у заявки, если их нет, обнуляет стейт
    //   : dispatch(setAppComments([]))
  }

  return (
    <>
      <Form
        className="app-work"
        name="app-info"
        layout={'vertical'}
        onFinish={onChangeStatus}
      >
        <Form.Item style={{ width: '100%' }}>
          <h2 className="app-header">
            <span className="back-btn" onClick={onClose}></span>
            Заявка номер: {application.app_num}
          </h2>
        </Form.Item>
        <Form.Item className="form-item" label="Дата">
          <p className="form-input info-text">{application.app_date}</p>
        </Form.Item>
        <Form.Item className="form-item" label="ФИО">
          <p className="form-input info-text">{application.sname}</p>
        </Form.Item>
        {application.phone && (
          <Form.Item className="form-item" label="Телефон">
            <p className="form-input info-text">{application.phone}</p>
          </Form.Item>
        )}
        {application.messanger && (
          <Form.Item className="form-item" label="Другие способы связи">
            {/*<Input*/}
            {/*  disabled={true}*/}
            {/*  value={application.messanger}*/}
            {/*  className="form-input info-text"*/}
            {/*/>*/}
            <p className="form-input info-text">{application.messanger}</p>
          </Form.Item>
        )}
        <Form.Item className="form-item" label="Статус">
          <Select
            showArrow={false}
            disabled={true}
            className="form-input disabled-select"
            value={application.app_status}
          >
            {statusList.map((el) => {
              return (
                <Option key={el.code} value={el.code}>
                  {el.lex1}
                </Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item className="form-item" label="Продукт">
          <p className="form-input info-text">{application.product}</p>
        </Form.Item>
        <Form.Item className="form-item" label="Тема">
          <p className="form-input info-text">{application.topic}</p>
        </Form.Item>
        <Form.Item
          className="form-item"
          label={`Обязательные параметры (${application.param || 'нет'})`}
        >
          <p className="form-input info-text">{application.param_info}</p>
        </Form.Item>
        <Form.Item className="form-item" label="Описание проблемы">
          <Input.TextArea
            autoSize={{ minRows: 1, maxRows: 20 }}
            style={{ color: 'rgba(0,0,0,.85)' }}
            disabled={true}
            value={application.descript}
            className="form-input"
          />
          {application.file_name && (
            <p
              className="file-link"
              onClick={() =>
                dispatch(getFileAppInfo(application.app_id, application.file_name))
              }
            >
              Скачать прикрепленный файл {application.file_name}
            </p>
          )}
        </Form.Item>
        {!isNewStatus && (
          <div className="chat-btn">
            <Button size="large" icon={<WechatOutlined />} onClick={onOpenComments}>
              Комментарии
            </Button>
          </div>
        )}
        {isPersonalRole &&
          !isFinishedStatus && ( //Кнопки, которые видны только работникам (тех. поддержке)
            <Form.Item style={{ width: '100%' }}>
              <Button
                type="primary"
                className="btn-submit application-submit-btn"
                onClick={() => {
                  setStatus(isNewStatus ? 2 : 4)
                }}
                style={{ borderRadius: '10px' }}
                htmlType="submit"
              >
                {isNewStatus ? 'Принять' : 'Завершить'}
              </Button>
              {isInWorkStatus && (
                <>
                  <Select
                    className="form-input inwork-select"
                    defaultValue={solutionMethod}
                    onSelect={(value) => setSolutionMethod(value)}
                  >
                    {solutionMethods.map((el) => {
                      return (
                        <Option key={el.code} value={el.code}>
                          {el.lex1}
                        </Option>
                      )
                    })}
                  </Select>
                  <div className="aplication-additional-btns">
                    <Button
                      className="btn-submit"
                      type="primary"
                      onClick={() => setOpenChangePers(true)}
                      style={{ borderRadius: '10px' }}
                    >
                      Передать исполнителю
                    </Button>
                    <Button
                      type="primary"
                      className="btn-submit"
                      style={{ borderRadius: '10px' }}
                      onClick={onToTest}
                      disabled={application.is_test !== 0}
                    >
                      Передать на тестирование
                    </Button>
                  </div>
                </>
              )}
            </Form.Item>
          )}
      </Form>
      <CommentsWindow openComments={openComments} setOpenComments={setOpenComments} />
      <ChangePersWindow
        openChangePers={openChangePers}
        setOpenChangePers={setOpenChangePers}
        afterClose={statusChangeCallback}
      />
    </>
  )
}
export default ApplicationWork
