export const Urls = () => {
 // const path = `http://192.168.101.81:8080`
 //  const path = `https://support.asterus.by`
  const path = `http://192.168.36.2:9900`
  return {
    /////////////////////////////////////////////////////////
    //////////////////////////APPLICATIONS//////////////////////
    GetNewAppList: `${path}/Application/GetNewAppList`,
    GetDoneAppList: `${path}/Application/GetDoneAppList`,
    InWorkByClient: `${path}/Application/InWorkByClient`,
    GetInWorkList: `${path}/Application/GetInWorkList`,
    DoneByClient: `${path}/Application/DoneByClient`,
    AppListByClient: `${path}/Application/AppListByClient`,
    NewByClient: `${path}/Application/NewByClient`,
    CreateApplication: `${path}/Application/CreateApplication`,
    ExtAppSearch: `${path}/Application/ExtAppSearch`,
    GetStatList: `${path}/Application/GetStatList`,
    GetAppInf: `${path}/Application/GetAppInf`,
    AppStatChange: `${path}/Application/AppStatChange`,
    GetMess: `${path}/Application/GetMess`,
    AddMess: `${path}/Application/AddMess`,
    GetProducts: `${path}/Application/GetProducts`,
    GetTopics: `${path}/Application/GetTopics`,
    GetDepart: `${path}/Application/GetDepart`,
    GetStat: `${path}/Application/GetStat`,
    AppTransfer: `${path}/Application/AppTransfer`,
    GetFile: `${path}/Application/GetFile`,
    /////////////////////////////////////////////////////////
    //////////////////////////PROFILES//////////////////////
    GetClientForUPD: `${path}/Profiles/GetClientForUPD`,
    GetPersForUpd: `${path}/Profiles/GetPersForUpd`,
    PersUpd: `${path}/Profiles/PersUpd`,
    ClientUpd: `${path}/Profiles/ClientUpd`,
    GetPostList: `${path}/Profiles/GetPostList`,
    GetDeptList: `${path}/Profiles/GetDeptList`,
    /////////////////////////////////////////////////////////
    //////////////////////////KADRY//////////////////////
    ADDUSER: `${path}/Kadry/ADDUSER`,
    DELUSER: `${path}/Kadry/DELUSER`,
    PasswordChange: `${path}/Kadry/PasswordChange`,
    GetUsersList: `${path}/Kadry/GetUsersList`,
    GetName: `${path}/Kadry/GetName`,
    SearchUser: `${path}/Kadry/SearchUser`,
    UpdUser: `${path}/Kadry/UpdUser`,
    AuthUser: `${path}/Kadry/AuthUser`,
    GetClientList: `${path}/Kadry/GetClientList`,
    GetPersSupport: `${path}/Kadry/GetPersSupport`,
    /////////////////////////////////////////////////////////
    //////////////////////////REFBOOK//////////////////////
    GetKlTypes: `${path}/Refbook/GetKlTypes`,
    GetKlUni: `${path}/Refbook/GetKlUni`,
    AddKlUni: `${path}/Refbook/AddKlUni`,
    GetTypeDepend: `${path}/Refbook/GetTypeDepend`,
    GetDependUni: `${path}/Refbook/GetDependUni `,
    DelKlUni: `${path}/Refbook/DelKlUni `,
    UpdKlUni: `${path}/Refbook/UpdKlUni `,
    GetCostumers: `${path}/Refbook/GetCostumers `,
  }
}

export default Urls
