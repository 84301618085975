import { Form, Input, Upload, Button, Select } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { getStatuses, setIsCreateApp } from '../../../slices/applicationSlice'
import { useSelector, useDispatch } from 'react-redux'
import useHttp from '../../../hooks/useHttp'
import { useEffect, useMemo, useState } from 'react'
import {
  getTopics,
  selectProducts,
  selectTopics,
  setLoadingFalse,
  setLoadingTrue,
} from '../../../slices/variableListSlice'
import {
  error,
  filterHandbookValuesArray,
  rules,
  success,
} from '../../../constants/helpers'
import Urls from '../../../constants/urls'
import { useUserInfo } from '../../../hooks/useUserInfo'
const { Option } = Select

const CreateApplication = () => {
  const [form] = Form.useForm()
  const { postRequestHead } = useHttp()
  const dispatch = useDispatch()
  const { userClientId, clientMainProduct, clientProductsList } = useUserInfo()
  const urls = Urls()

  const products = useSelector(selectProducts)
  const filteredProducts = useMemo(() => {
    return filterHandbookValuesArray(products, clientProductsList)
  }, [clientProductsList, products])
  const topics = useSelector(selectTopics)
  const [topic, setTopic] = useState('')
  const [description, setDescription] = useState('')
  const [paramName, setParamName] = useState('')
  const [paramValue, setParamValue] = useState('')
  const [file, setFile] = useState(null)
  const [product, setProduct] = useState(clientMainProduct)

  const uploadProps = {
    onRemove: () => {
      setFile(null)
    },
    beforeUpload: (file) => {
      setFile(file)
      return false
    },
    maxCount: 1,
  }

  const createApplicationCallback = (response) => {
    dispatch(setLoadingFalse())
    if (response.tag) {
      error(response.message)
    } else {
      success(response.data[0].response)
      const obj = {
        P_CLIENT_ID: userClientId,
      }
      dispatch(getStatuses(urls.GetStatList, obj))
      form.resetFields()
      setFile(null)
      setDescription('')
    }
  }

  const onChangeTopic = (value, paramName) => {
    setTopic(value)
    setParamName(paramName)
  }

  const handleSubmit = async () => {
    const formData = new FormData()
    formData.append('p_file', file)
    formData.append('p_client_id', userClientId)
    formData.append('p_product', product)
    formData.append('p_topic', topic)
    formData.append('p_descript', description)
    formData.append('p_param', paramValue)
    dispatch(setLoadingTrue())
    postRequestHead({
      url: urls.CreateApplication,
      data: formData,
      callback: createApplicationCallback,
    })
  }

  useEffect(() => {
    dispatch(getTopics(urls.GetTopics, product))
  }, [dispatch, product])

  return (
    <>
      <Button
        icon={<CloseOutlined />}
        onClick={() => dispatch(setIsCreateApp(false))}
      ></Button>
      <Form
        className="create-application"
        name="create-application"
        labelCol={{
          span: 12,
        }}
        layout={'vertical'}
        style={{ background: '#fff', padding: '0.5rem 2rem', width: '65%' }}
        onFinish={handleSubmit}
        form={form}
        initialValues={{ product: product }}
      >
        <Form.Item>
          <h2>Создать Заявку</h2>
        </Form.Item>

        <Form.Item label="Продукт" name="product" className="form-item" rules={rules}>
          <Select
            className="form-input app-info disabled-select"
            showArrow={false}
            onSelect={(value) => setProduct(value)}
            value={product}
          >
            {filteredProducts.map((el) => {
              return (
                <Option key={el.code} value={el.code}>
                  {el.lex1}
                </Option>
              )
            })}
          </Select>
        </Form.Item>
        <Form.Item className="form-item" name="topic" label="Тема" rules={rules}>
          <Select
            className="form-input"
            onSelect={(_, option) => onChangeTopic(option.value, option.title)}
          >
            {topics.map((el) => {
              return (
                <Option key={el.code} value={el.code} title={el.lex3}>
                  {el.lex1}
                </Option>
              )
            })}
          </Select>
        </Form.Item>
        {topic && (
          <Form.Item
            label={`Обязательные параметры (${paramName})`}
            name="parameter"
            className="form-item"
            rules={rules}
          >
            <Input
              className="form-input"
              onChange={(e) => setParamValue(e.target.value)}
            />
          </Form.Item>
        )}
        <Form.Item label="Описание проблемы" name="description" className="form-item">
          <Input.Group>
            <Upload {...uploadProps} name="upload">
              <Button>Прикрепить файл</Button>
            </Upload>
            <Input.TextArea
              className="form-input"
              onChange={(e) => setDescription(e.target.value)}
            />
          </Input.Group>
        </Form.Item>

        <Form.Item>
          <button className="btn-submit">Создать</button>
        </Form.Item>
      </Form>
    </>
  )
}

export default CreateApplication
