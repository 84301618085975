import { Form, Select, Input } from 'antd'
import { useState } from 'react'
import UploadAndDisplayImage from '../UploadImage'
import useHttp from '../../hooks/useHttp'
import { useDispatch, useSelector } from 'react-redux'
import { roles } from '../../constants'
import { getUserName } from '../../slices/userListSlice'
import { getUserForUpd } from '../../slices/settingSlice'
import { selectDepartments, selectPosts } from '../../slices/variableListSlice'
import {error, success} from '../../constants/helpers'
import Urls from '../../constants/urls'
import { useUserInfo } from '../../hooks/useUserInfo'
const { Option } = Select

const UserSettingForm = ({ user, role }) => {
  const dispatch = useDispatch()
  const { userId, userClientId, userPersId } = useUserInfo()
  const urls = Urls()
  const deptList = useSelector(selectDepartments)
  const postList = useSelector(selectPosts)
  const { postRequest } = useHttp()

  const [selectedImage, setSelectedImage] = useState(user.p_image || '')
  const [surname, setSurname] = useState(user.p_surname || '')
  const [userName, setUserName] = useState(user.p_firstname || '')
  const [fatherName, setFatherName] = useState(user.p_fathername || '')
  const [phone, setPhone] = useState(user.p_phone || '')
  const [mail, setMail] = useState(user.p_mail || '')
  const [messanger, setMessanger] = useState(user.p_messanger || '')
  const [post, setPost] = useState(user.p_post)
  const [department, setDepartment] = useState(user.p_depart)
  const [password, setPassword] = useState('')

  const isClientRole = role === roles.client || role === roles.clientAdmin

  //Колбэки

  const updateUserCallback = (resp) => {
    if (resp.tag) {
      error(resp.message)
    }   else {

        success(resp.data[0].message)

        dispatch(getUserName(urls.GetName, userId))
        if (isClientRole) {
          const obj = { P_CLIENT_ID: userClientId }
          dispatch(getUserForUpd(urls.GetClientForUPD, obj))
        } else {
          const obj = { P_PERS_ID: userPersId }
          dispatch(getUserForUpd(urls.GetPersForUpd, obj))
        }


    }

  }

  //Слушатели событий
  const onSaveBtn = () => {
    const clientInfo = {
      p_client_id: userClientId,
      p_image: selectedImage,
      p_org: department,
      p_post: post,
      p_mail: mail,
      p_phone: phone,
      p_messanger: messanger,
      p_surname: surname,
      p_firstname: userName,
      p_fathername: fatherName,
      p_pass: password,
    }
    const userInfo = {
      p_pers_id: userPersId,
      p_image: selectedImage,
      p_mail: mail,
      p_pass: password,
      p_surname: surname,
      p_firstname: userName,
      p_fathername: fatherName,
    }
    postRequest({
      url: isClientRole ? urls.ClientUpd : urls.PersUpd,
      data: isClientRole ? clientInfo : userInfo,
      callback: updateUserCallback,
    })
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', gap: '10%' }}>
      <UploadAndDisplayImage
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
      />
      <Form
        className="create-user"
        name="update-user"
        layout={'vertical'}
        style={{ width: '35%', background: '#fff', padding: '1rem' }}
        onFinish={onSaveBtn}
      >
        <Form.Item>
          <h2>Изменение личной информации</h2>
        </Form.Item>

        <Form.Item
          className="form-item"
          label="Логин (Просмотр)"
          style={{ cursor: 'not-allowed' }}
        >
          <div
            className="form-input"
            style={{ padding: '0.3rem 0.5rem', fontWeight: '400' }}
          >
            {user.p_login}
          </div>
        </Form.Item>
        <Form.Item className="form-item" label="Email">
          <Input
            className="form-input"
            value={mail}
            onChange={(e) => setMail(e.target.value)}
            placeholder="Email@gmail.com"
          />
        </Form.Item>
        <Form.Item className="form-item" label="Сменить пароль">
          <Input
            className="form-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="123"
          />
        </Form.Item>

        <Form.Item className="form-item" label="Фамилия">
          <Input
            className="form-input"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Имя" className="form-item">
          <Input
            className="form-input"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Отчество" className="form-item">
          <Input
            className="form-input"
            value={fatherName}
            onChange={(e) => setFatherName(e.target.value)}
          />
        </Form.Item>
        {isClientRole && (
          <>
            <Form.Item className="form-item" label="Телефон">
              <Input
                className="form-input"
                // type='number'
                // mask="+375 (__) ___ __ __"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="+375(29)111-11-11"
              />
            </Form.Item>
            <Form.Item className="form-item" label="Другие способы связи">
              <Input
                className="form-input"
                value={messanger}
                onChange={(e) => setMessanger(e.target.value)}
                placeholder="telegram: @user/viber/WhatsApp"
              />
            </Form.Item>

            <Form.Item className="form-item" label="Подразделение/Организация">
              <Select
                className="form-input"
                value={department}
                onSelect={(_, option) => setDepartment(option.value)}
              >
                {deptList.map((el) => {
                  return (
                    <Option key={el.code} value={el.code}>
                      {el.lex1}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
            <Form.Item className="form-item" label="Должность">
              <Select
                className="form-input"
                value={post}
                onSelect={(_, option) => setPost(option.value)}
              >
                {postList.map((el) => {
                  return (
                    <Option key={el.code} value={el.code}>
                      {el.lex1}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </>
        )}
        <Form.Item>
          <button className="btn-submit">Сохранить</button>
        </Form.Item>
      </Form>
    </div>
  )
}
export default UserSettingForm
